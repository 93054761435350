import React from 'react'

const OurAreas = () => {
    return (
        <>

            <section className="target">
                <div className="container">
                    <div className="sec-title text-center bg-fill mb-3">
                        <span className="title">Our Areas</span>
                        {/* <h2 className="text-white">Ethics Behind Success</h2> */}
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="target-block text-white p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-users fa-3x color-orange mb-3 target-icon" />
                                <br />
                                <h4 className="fw-bold">Assessment</h4>
                                <p>Assess competency levels of learners.</p>
                                <p>Conduct diagnostic tests.</p>
                                <p>Counsel learners to understand current competency levels and where they wish to go, helps in choosing the right course.</p>
                               
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white  p-5 mb-lg-0 mb-xl-0 mb-md-0 mb-sm-5 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-mortar-board fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Certification</h4>
                                <p>Certification is a process and learners can use them to apply for higher education or employment in Thailand and Abroad depending on the levels achieved.</p>
                                <p>Certification from Cambridge is recognized worldwide for learners to apply for migration to a different country for higher education or employment.</p>
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="target-block text-white p-5" data-aos="fade-up" data-aos-duration={550}>
                                <div className="border-line" />
                                <i className="fa fa-book fa-3x color-orange mb-3 target-icon" />
                                <h4 className="fw-bold">Intervention </h4>
                                <p>Interventions help learners to achieve their learning goals.</p>
                                <p>Learners are exposed to platforms of technology that is used in Cambridge examinations.</p>
                                <p>Builds confidence in learners and helps them to face examination without any fear.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default OurAreas