import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/Home';
import { useEffect } from 'react';
import MainLayout from './components/MainLayout';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import Faculty from './pages/Faculty';


function App() {

  function ScrolltoTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }


  return (
    <>

      <BrowserRouter>
        <ScrolltoTop />
        <Routes>
          <Route path='/' element={<MainLayout />} >
            <Route index element={<Home />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/courses' element={<Courses />} />
            <Route path='/faculty' element={<Faculty />} />
            <Route path='/contact' element={<Contact />} />

          </Route>
        </Routes>
      </BrowserRouter>


    </>
  );
}

export default App;
