import React, { useState } from 'react';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';


const Hadear = () => {
  const [ismobile, setIsmobile] = useState(0);
  return (
    <>

      <header className="top-header py-2">
        <div className="container">
          <div className="row align-content-center">
            <div className="col-xl-2 col-lg-3 col-md-4 py-2 fw-bold f-15 announcement">
              <i className="fa fa-bullhorn me-3" />
              <span>Announcement</span>
            </div>
            <div className="col-xl-7 col-lg-5 col-md-8">
              <p className="marquee">
                <span> Accessible Academy fluency starts here
                </span>
              </p>
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container">
          <Link className="navbar-brand">
            <div className="logo-wrapper">
              <Link className='d-flex justify-content-center align-items-center' to="/">
                <img src="assets/images/Accessible Academy.png" className="img-fluid" alt="Edusquad" />
              </Link>
            </div>
          </Link>
          <div className={ismobile ? "moblink" : "desklink"} onClick={() => setIsmobile(false)}>
            <ul>
              <Link className=" text-decoration-none" to="/">
                <li >HOME</li>
              </Link>
              <Link className=" text-decoration-none" to="/about">
                <li className='li' >ABOUT</li>
              </Link>
              <li>
                <Link className=" text-decoration-none" to="/courses">
                  <li> COURSES </li>
                </Link>
              </li>
              <li>
                <Link className=" text-decoration-none" to="/faculty">
                  <li> Faculty </li>
                </Link>
              </li>

              <li>
                <Link className=" text-decoration-none" to="/contact">
                  <li> CONTACT</li>
                </Link>
              </li>

            </ul>
          </div>

          <button className='vavbutn' onClick={() => setIsmobile(!ismobile)}>
            {ismobile ? < IoMdClose /> : < GiHamburgerMenu />}
          </button>
        </div>
      </nav>
    </>
  )
}
export default Hadear