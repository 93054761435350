import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>

      <footer className="theme-blue">
        <div className="container">
          <div className="footer-top border-bottom pt-5">
            <div className="row">
              <div className="col-lg-3 col-md-6" data-aos="fade-in" data-aos-duration={1050}>
                {/* <Link to="/">
                  <img src="assets/images/898247.jpg" className="img-fluid mb-3" alt="Edusquad" />
                 
                </Link> */}
                 <h5 className="fw-bold mb-3">About</h5>
                <p>We specialize in training candidates to take proficiency exams as well, in order to fortify them with international and national qualifications that are mapped to the Thailand </p>
                {/* <ul className="social-icon">
                  <li>
                    <Link to="javascript:">
                      <i className="fa fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="javascript:">
                      <i className="fa fa-google-plus" />
                    </Link>
                  </li>
                  <li>
                    <Link to="javascript:">
                      <i className="fa fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="javascript:">
                      <i className="fa fa-skype" />
                    </Link>
                  </li>
                  <li>
                    <Link to="javascript:">
                      <i className="fa fa-linkedin" />
                    </Link>
                  </li>
                </ul> */}
              </div>
              <div className="col-lg-2 col-md-6" data-aos="fade-in" data-aos-duration={550}>
                <h5 className="fw-bold mb-3">Quick Links</h5>
                <ul>
                  <li>
                    <Link to="/">
                      <i className="fa fa-angle-double-right me-2" />Home </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <i className="fa fa-angle-double-right me-2" />About Us </Link>
                  </li>
                  <li>
                    <Link to="/courses">
                      <i className="fa fa-angle-double-right me-2" /> Coures </Link>
                  </li>
                  <li>
                    <Link to="/faculty">
                      <i className="fa fa-angle-double-right me-2" />Faculty </Link>
                  </li>


                  <li>
                    <Link to="/contact">
                      <i className="fa fa-angle-double-right me-2" />Contact </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 mb-md-5 mb-4" data-aos="fade-in" data-aos-duration={1050}>
                <h5 className="fw-bold mb-3">Recent News</h5>
                <div className="row">
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/thumb_1.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/thumb_2.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 mb-2 pe-0">
                    <img src="assets/images/thumb_3.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_4.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_5.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                  <div className="col-md-4 col-sm-2 col-2 pe-0">
                    <img src="assets/images/thumb_6.jpg" className="img-fluid" alt="Gallery" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ps-lg-5  mb-md-5" data-aos="fade-in" data-aos-duration={1050}>
                <h5 className="fw-bold mb-3">Contact Us</h5>
                <ul className="address-icon">
                  <li className="mb-3">
                    <i className="fa fa-map-marker me-3 color-orange" />
                  <p>31/3, Moo-3, Na Jomtien, Sattahip, Chonburi 20250 Thailand</p>
                  </li>
                  {/* <li className="mb-3">
                    <i className="fa fa-map-marker me-3 color-orange" />
                    Mr Ahmed Al Wasl,<br /> PO Box 12345 Dubai UAE
                  </li> */}
                  <li className="mb-3">
                    <i className="fa fa-phone me-3 color-orange" />
                    <a href="tel: +083 2574 136">
                    +083 2574 136
                    </a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-phone me-3 color-orange" />
                    <a href="tel: +086 6777 399">
                   + 086 6777 399
                    </a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-envelope color-orange me-3" />
                    <a
                      href="mailto:info@accessibleacademy.com"
                      class="mail"
                    >
                      info@accessibleacademy.com
                    </a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-envelope color-orange me-3" />
                    <a
                      href="mailto:contact@accessibleacademy.com"
                      class="mail"
                    >
                      contact@accessibleacademy.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ul className=" bg-transparent" style={{ position: "sticky",zIndex:999,bottom:20,  }}>
          <li className='none' style={{listStyle:"none"}}>
            <div className="wp-icon">
              <a href=" https://wa.me/521356139" target="_blank">
                <img
                  style={{marginLeft:10 ,width: 45, height: 45 }}
                  src="assets/images/whatsapp-icon.png"
                  alt=""
                />
              </a>
            </div>
          </li>
        </ul>
      </footer>

      
      

    </>
  )
}

export default Footer