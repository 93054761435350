import React from 'react'
import { Link } from "react-router-dom";


const Gea = () => {
    return (
        <>

            <section className="online-course">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="sec-title">
                                {/* <span className="title">Online Courses</span> */}
                                <h2>Accessible Academy</h2>
                                <div className="divider">
                                    <span className="fa fa-mortar-board" />
                                </div>
                            </div>
                            <p className="p-17">At Accessible Academy, we offer you a multi cultural learning experience on a world class online/offline platform. We are a powerhouse of experienced academic excellence; innovative teaching methodologies allied with rich and varied learning resources. We believe, learning is a holistic experience and is not restricted to just academics.</p>
                            <p className="p-17">With the objective of rising the English language communication level of the participants, this program is built with individual’s and the organization’s needs in mind. The outcome of these programs is aimed at increasing proficiency and assist in working in a global perspective:</p>
                            <button type="button" className="btn theme-orange theme-btn my-2 join-us"><Link to="/contact">Join Us</Link></button>
                        </div>
                        <div className="col-lg-6">
                            <div className="course-block  d-flex justify-content-between bg-light pa-2 mx-5 my-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/promotion.png" className="img-fluid m-auto" alt="Key Of Success" />
                                <div className="course-text ps-5">
                                    <h4>Expertise</h4>
                                    <p>Decades of experience of successfully delivering language learning for the public and private sectors in the Thailand.</p>
                                </div>
                            </div>
                            <div className="course-block  d-flex justify-content-between theme-blue pa-2 mx-5 my-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/online-class.png" className="img-fluid m-auto" alt="Our Philosophy" />
                                <div className="course-text ps-5">
                                    <h4>Accessibility</h4>
                                    <p>Our live online courses are accessible from anywhere.  you can join our classroom regardless of location.</p>
                                </div>
                            </div>
                            <div className="course-block  d-flex justify-content-between theme-orange pa-2 mx-5 mt-3" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/icons/feminism.png" className="img-fluid m-auto" alt="Our Principle" />
                                <div className="course-text ps-5">
                                    <h4>The Accessible Academy Way</h4>
                                    <p>Modern course design and an outcomes-based approach to learning ensures that the right content and activities get you to fluency in no time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Gea