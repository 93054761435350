import React from 'react'

const Faculty = () => {
  return (
    <>

      <section className="inner-banner">
        <h1 className="fw-bold text-center">FACULTY</h1>
      </section>


      <div className='mt-lg-5'></div>
      <section className="video-faq">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 ps-0 small-screen">
              <div className="video-area">

              </div>
            </div>
            <div className="col-lg-6 col-md-6 p-5" data-aos="fade-up" data-aos-duration={550}>
              <div className="sec-title bg-fill">
                <span className="title">FACULTY</span>
                <div className="divider">
                  <span className="fa fa-mortar-board" />
                </div>
              </div>
              <div className="panel-group pt-3" id="accordion2" role="tablist" aria-multiselectable="true">

                <div className="panel panel-default mb-4">
                  <div className="panel-heading" role="tab" id="headingThree2">
                    <h4 className="panel-title pt-lg-5">
                      <a className="collapsed " role="button" data-toggle="collapse" data-parent="#accordion2" aria-expanded="false">
                        Our experienced instructors are Cambridge Certified trainers who have taught both in Thailand and abroad. They are skilled in conducting sessions without the aid of an interpreter and have specialized knowledge in teaching international students from non-English speaking countries. To enhance the student's learning experience, we design our sessions to include relevant anecdotes, powerful narrations, and experiential learning modules.
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 mt-lg-5">
              <p>Each member of our faculty is dedicated to fostering and improving the quality of teaching and creating exceptional learning experiences. Our academicians encourage, facilitate, and support individual and collaborative inquiries, resulting in a deeper understanding and sustained improvement in language learning.</p>
              <p>At Accessible Academy, our teachers possess a unique set of strengths that foster optimal learning experiences. Their strengths include integral communication and interactive learning, which are essential in today's digital age. They have a deep understanding of multimedia tools and how to use them to encourage classroom participation and learning. Our instructors are highly skilled in interpersonal communication, which enables them to connect with their pupils and facilitate meaningful interactions that contribute to their growth.</p>
            </div>
            <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
              <div className="about-img">
                <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                  <div className="border-line" />
                  <img src="assets/images/about1.webp" className="img-fluid" alt="About" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="aboutus bg1">
        <div className="container">
          <div className="row">

            <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-0">
              <div className="about-img">
                <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                  <div className="border-line" />
                  <img src="assets/images/course_12.webp" className="img-fluid" alt="About" />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 mt-lg-4">

              <p> Our approach involves providing personalized attention to each student, and we create individualized programs tailored to meet their unique learning requirements. Our instructors are flexible and able to adapt their approach to meet the demands of each student, ensuring that every student receives the instruction they require. Our success record on tests like the IELTS, TOEFL, and OET speaks to our ability to deliver quality results, and we take pride in our students' achievements.</p>
              <p> At Accessible Academy, we understand the importance of tracking each student's progress to ensure their growth and success. That's why we've developed a comprehensive student dashboard that allows students to keep track of their progress while enabling our teaching team to make informed plans that align with each student's unique needs.</p>
              <p> Our faculty members are highly flexible and modern in their approach to education, allowing us to provide high-quality instruction both online and offline. With our cutting-edge teaching methods, we aim to lead the industry by providing the most effective learning experience for our students.</p>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Faculty