import React from 'react'
import { Link } from "react-router-dom";


const HomeCourses = () => {
    return (
        <>

            <section className="blog">
                <div className="container">
                    <div className="sec-title text-center mb-3">
                        <span className="title">Course</span>
                     
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/blog1.webp" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <h5 className="fw-bold">TOEFL Exam Preparation Center</h5>
                                    <p>Accessible Academy offers a variety of practice materials to help you prepare for the TOEFL test and build your English skills. Materials include free sample questions, practice tests, interactive skill-building programs, and detailed tips and information for understanding more about the test. Our tried and tested exam strategies will help learners excel in all of the four skills and achieve the score they need without the need for repeated retakes.</p>
                                   
                                    <Link to="/courses" className="btn color-orange read-more mt-3">Conect</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/blog_3.webp" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <h5 className="fw-bold">PTE Training</h5>
                                    <p>All our native instructors agree that PTE is easier than IELTS and we have the tips and training to get you the result you need. Starting your PTE training with us now is the best way to pass the test first time. PTE Academic is one of the main requirements for Australian and New Zealand immigration and can also be used for university entrance. Our students benefit from our expert tips, advice, PTE speaking templates and personal feedback for PTE writing.</p>
                                    
                                    <Link to="/courses" className="btn color-orange read-more mt-3">Conect</Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                <img src="assets/images/blog_2.webp" className="img-fluid" alt="Blog" />
                                <div className="post_inner p-3">
                                    <h5 className="fw-bold">IELTS</h5>
                                    <p>IELTS is designed to assess the language ability of candidates who need to study or work where English is the language of communication. They are recognized by more than 9,000 colleges, universities and agencies in more than 130 countries, including Australia, Canada, the UK and the USA.</p>
                                    {/* <p>Our IELTS Preparation courses are carefully structured and specifically designed to help you prepare to successfully perform in the test. Preparing for these tests significantly increases your chance of getting the score that you need. This program is for those who use English competently but need to develop reading, writing, listening and speaking skills.</p> */}
                                   
                                    <Link to="/courses" className="btn color-orange read-more mt-3">Conect</Link>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeCourses