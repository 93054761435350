import React from 'react'

const Courses = () => {
    return (
        <>
            <section className="inner-banner">
                <h1 className="fw-bold text-center">Course</h1>
            </section>


            <div className='mt-lg-5'></div>
            <section className="video-faq">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 ps-0 small-screen">
                            <div className="video-area">

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 p-5" data-aos="fade-up" data-aos-duration={550}>
                            <div className="sec-title bg-fill">
                                <span className="title">All Courses Now Available Live Online</span>
                                <div className="divider">
                                    <span className="fa fa-mortar-board" />
                                </div>
                            </div>
                            <div className="panel-group pt-3" id="accordion2" role="tablist" aria-multiselectable="true">

                                <div className="panel panel-default mb-4">
                                    <div className="panel-heading" role="tab" id="headingThree2">
                                        <h4 className="panel-title pt-lg-5">
                                            <a className="collapsed " role="button" data-toggle="collapse" data-parent="#accordion2" aria-expanded="false">
                                                Searching for the best online English classes? Our online spoken English courses in Dubai are taught by British and American tutors and provide the student with the perfect learning environment to improve their English conversation and writing skills. Also, we provide private tutors for one-to-one online English courses.
                                            </a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="course">
                <div className="container">
                    <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                        <span className="title">Popular Courses</span>
                        <h2>Courses covering a broad range of topics</h2>
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="tab-content" id="pills-tabContent">
                            <div aria-labelledby="showall-tab" className="tab-pane  fade show active" id="showall" role="tabpanel">
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="img-fluid " src="assets/images/course_1.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">CELPIP</h5>
                                        <p>English Proficiency Test designed by Canadian authorities for immigration to Canada.</p>

                                    </div>
                                </div>
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="img-fluid" src="assets/images/course_2.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">SAT/ACT English</h5>
                                        <p>The English and Reading components of the entrance test for admissions to various American Colleges. Students use these scores to apply for undergraduate programs in America.</p>

                                    </div>
                                </div>
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt className="card-img" src="assets/images/course_3.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">GMAT</h5>
                                        <p>It is a computer adaptive test used to assess certain analytical, writing, quantitative, verbal, and reading skills in written English for use in admission to a Graduate Management Program.</p>

                                    </div>
                                </div>
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="card-img" src="assets/images/course_6.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">Cambridge Exam</h5>
                                        <p>English Proficiency Exams that are designed for every band of the CEFR. These are language qualification exams and can be used in a CV or Resume. Used for university admissions and migration.</p>

                                    </div>
                                </div>
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="card-img" src="assets/images/course_7.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">TOEFL</h5>
                                        <p>English Proficiency Exam that is a computer based online exam to help assess proficiency in English. Used for university admissions and migration.</p>

                                    </div>
                                </div>
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="card-img" src="assets/images/course_8.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">GRE</h5>
                                        <p>GRE is a graduate entrance test that is accepted in my universities in America and Canada for various graduate and management programs.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="tab-content" id="pills-tabContent">
                            <div aria-labelledby="showall-tab" className="tab-pane  fade show active" id="showall" role="tabpanel">
                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="img-fluid " src="assets/images/course_1.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">Writing Skills</h5>
                                        <p>Writing Skills training is offered in the form of a workshop for learners who wish to hone the skills of professional writing that will help them enhance their role within an organization or to better represent their organization. Interventions help learners to professionally write Press Communication, Inter-office communication, email etiquette, Business letters, memos, proof reading and editing of business documents.</p>

                                    </div>
                                </div>

                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                         {/* <img alt className="card-img" src="assets/images/course_3.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">Business English</h5>
                                        <p>Fluency in English, both written and spoken plays a critical role in many aspects of corporate life from securing employment to communicating with clientele and achieving cohesive business partnerships all over the world. Business English refers to the communication skills used in the workplace, and focuses on the language and skills needed for typical business communication such as presentations, negotiations, meetings, small talk, socializing, correspondence, report writing, and a systematic approach.</p>

                                    </div>
                                </div>


                                <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* <img alt="Course" className="img-fluid" src="assets/images/course_2.png" /> */}
                                    <div className="course-desc py-4 px-3">
                                        <h5 className="fw-bold">Spoken English</h5>
                                        <p>Speaking English allows you to actually broaden your world, from job opportunities to the ability to relate to people from every country. A course in spoken English may be either in the form of ‘what to say’ or in the form of ‘how to say’. The former is usually focused on English conversation, the later on pronunciation. The former aims to teach how to express communicative functions such as asking questions, making requests, getting things done or expressing greetings, farewell, apologies, regrets, thanks, etc and the latter aims at teaching the pronunciation of words, phrases and sentences. Our classes combine the best of the two areas to ensure that the participant learns what to say with how to say.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Courses