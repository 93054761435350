import React, { useState } from 'react'
import emailjs from "@emailjs/browser";


const Contact = () => {


    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
    const [number, setNumber] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };

    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };
    const numberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(

                "service_f36xi26",
                "template_zm88t8j",
                "#myForm",
                "RnYOVlDPnYyYN8khd"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setmail("");
                        setMassages("");
                        setNumber("");

                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>


            <div>
                <section className="inner-banner">
                    <h1 className="fw-bold text-center">Contact Us</h1>
                </section>
                <section className="contact-section">
                    <div className="container">
                        <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                            <span className="title">Contact Us</span>
                            <h2>We’d Love to Hear From You</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="contact-form p-5" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="border-line" />
                                    {/* <h3 className="fw-bold color-orange">Drop Message</h3> */}

                                    {/* <img src="assets/images/contact.jpg" className="img-fluid rounded " alt="About" /> */}



                                    <form onSubmit={sendEmail} id="myForm">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputName">Name</label>
                                            <input className="form-control" placeholder="Enter Name" type="text"
                                                name="from_name"
                                                onChange={(e) => nameChange(e)}
                                                required
                                                value={name}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1">Email Address</label>
                                            <input className="form-control" placeholder="Enter Email" type="email"
                                                name='email'
                                                onChange={(e) => mailChange(e)}
                                                value={mail}
                                                required
                                            />
                                            <small className="form-text text-muted">We'll never share your email with anyone
                                                else.
                                            </small>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputNumber">Phone Number</label>
                                            <input className="form-control" placeholder="Enter Number" type="number"
                                                name="number"
                                                onChange={(e) => numberChange(e)}
                                                value={number}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputMessage">Message</label>
                                            <input className="form-control"  placeholder="Message" type="text"
                                                onChange={(e) => massagesChange(e)}
                                                name='massage'
                                                value={massages}
                                                required
                                            />
                                        </div>
                                        {/* <a className="btn theme-orange border-0 mt-4" type="submit"
                                            name="submit">Send Message</a> */}


                                        <a className="btn theme-orange border-0 mt-4">
                                            <input
                                                type="submit"
                                                name="submit"
                                                defaultValue="Submit Now"
                                            />
                                            
                                        </a>

                                    </form>


                                    {/* <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                                        <div className="" />
                                        <img src="assets/images/about_12.webp" className="img-fluid" alt="About" />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 ms-minus">
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue mb-3" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Address</h6>
                                        <p className="mb-0">31/3, Moo-3, Na Jomtien, Sattahip, Chonburi 20250 Thailand</p>
                                        {/* <p className="mb-0">Mr Ahmed Al Wasl,
                                            PO Box 12345 Dubai UAE</p> */}
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1050} src="assets/images/icons/location.png" alt="Location" />
                                </div>
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue mb-3" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Email</h6>
                                        <a
                                            href="mailto:  info@accessibleacademy.com"
                                            class="mail"
                                        >
                                            info@accessibleacademy.com
                                        </a>
                                        <p>contact@accessibleacademy.com</p>
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1500} src="assets/images/icons/mail.png" alt="Mail" />
                                </div>
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Contact Number</h6>
                                        <a href="tel: +083 2574 136">
                                            +083 2574 136
                                        </a>
                                        <br/>
                                        <a href="tel: +086 6777 399">
                                            +086 6777 399
                                        </a>
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1050} src="assets/images/icons/call.png" alt="Call" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Contact