import React from 'react'

const Homebanner = () => {
    return (
        <>

            <div id="demo" className="carousel slide" data-ride="carousel">
                <ul className="carousel-indicators">
                    <li data-target="#demo" data-slide-to={0} className="active" />
                    <li data-target="#demo" data-slide-to={1} />
                    <li data-target="#demo" data-slide-to={2} />
                    <li data-target="#demo" data-slide-to={3} />
                </ul>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="assets/images/banner_1.webp" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInLeft delay-0.5s">
                            <h1 className="fw-bold">Certify Your English, Amplify Your Ambitions </h1>
                            {/* <h6 className="banner-desc">Certify Your English, Amplify Your Ambitions</h6> */}
                            {/* <a href="javascript:" className="theme-orange btn mt-3 apply-now">Apply Now</a> */}
                        </div>
                    </div>
                    {/* <div className="carousel-item">
                        <img src="assets/images/banner_2.jpg" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInUp delay-0.5s">
                            <h1 className="fw-bold">Education is what remains after one has forgotten what one has learned in school.</h1>
                            <h6 className="banner-desc">We had such a great time in Education</h6>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="assets/images/banner_3.jpg" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInDown delay-0.5s">
                            <h1 className="fw-bold">Education is not the filling of a pail, but the lighting of a fire.</h1>
                            <h6 className="banner-desc">We had such a great time in Education</h6>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="assets/images/banner_4.jpg" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated zoomIn delay-0.5s">
                            <h1 className="fw-bold">Education is not preparation for life; education is life itself.</h1>
                            <h6 className="banner-desc">We had such a great time in Education</h6>
                        </div>
                    </div> */}
                </div>
            </div>


        </>
    )
}

export default Homebanner