import React from 'react'
import Homebanner from '../components/Homebanner'
import Gea from '../components/Gea'
import OurAreas from '../components/OurAreas'
import JoinUs from '../components/JoinUs'
import HomeCourses from '../components/HomeCourses'

const Home = () => {
  return (
    <>
   <Homebanner />
   <Gea />
   <OurAreas />
   <JoinUs />
   <HomeCourses />


    </>
  )
}

export default Home