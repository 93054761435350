import React from 'react'
// import JoinUs from '../components/JoinUs'

const AboutUs = () => {
    return (
        <>
            <section className="inner-banner">
                <h1 className="fw-bold text-center">About Us</h1>
            </section>

            <section className="aboutus">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h1 className="color-orange fw-bold about-title" data-aos="fade-up" data-aos-duration={1000}>10+</h1>
                            <h5 className="fw-bold text-uppercase mb-3" data-aos="fade-up" data-aos-duration={1000}>YEARS OF EXPERIANCE</h5>
                            <p>Accessible Academy is a company that was born from a long cherished dream which became a reality in July 2010 . Since then we have come a long way training and teaching students, teachers and adult learners from all walks of life.</p>
                            <p>We specialize in training candidates to take proficiency exams as well, in order to fortify them with international and national qualifications that are mapped to the Thailand .</p>
                            <p>Our training is system oriented and tailor made to suit every individual’s style of learning and learners’ needs. It is a constant endeavour to keep our training and methodologies in sync with current practices.</p>
                        </div>
                        <div className="col-lg-6 col-md-8 offset-md-2 offset-lg-0">
                            <div className="about-img">
                                <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <img src="assets/images/about1.webp" className="img-fluid" alt="About" />
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="target">
                <div className="container">
                    <div className="sec-title text-center bg-fill" data-aos="fade-up" data-aos-duration={1000}>
                        <span className="title">Why Choose Us</span>
                        {/* <h2 className="text-white">Ethics Behind Success</h2> */}
                        <div className="divider">
                            <span className="fa fa-mortar-board" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="target-block text-white p-lg-5 p-md-3 p-sm-3 p-2 mb-lg-0 mb-mb-0 mb-xl-0 mb-sm-5 mb-4" data-aos="flip-up" data-aos-duration={1000}>
                                <div className="border-line" />
                                <i className="fa fa-check-circle fa-3x color-orange mb-3" />
                                <h4 className="fw-bold">Mission</h4>
                                <p>“Towards excellence” is our tag line.The Symbol has the letters E and A and are placed in an ascending order to indicate an upward learning curve in the lives of candidates who are trained by us. The tick at the bottom assures that you are at the right place to be guided in the manner you desire as we have modules crafted to suit every learner’s needs.</p>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="target-block text-white  p-lg-5 p-md-3 p-sm-3 p-2 mb-lg-0 mb-mb-0 mb-xl-0 mb-sm-5 mb-4" data-aos="flip-up" data-aos-duration={1000}>
                                <div className="border-line" />
                                <i className="fa fa-eye fa-3x color-orange mb-3" />
                                <h4 className="fw-bold">Vision</h4>
                                <p>To be the largest service provider, providing training and offering various suites of exams in English at the national and International level. It is our vision to be the most sought institution for teaching, improving and enhancing the English language skills effectively.</p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <JoinUs /> */}
            <div className='mt-lg-5'></div>


        </>
    )
}

export default AboutUs