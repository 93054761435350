import React from 'react'

const JoinUs = () => {
    return (
        <>

            <section className="contact-us mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 contact-us-block">
                            <div className="border-line" />
                            <div className="row">
                                <div className="col-lg-6 col-md-12 py-5">
                                    <h3 className="text-center fw-bold">Testimonials</h3>
                                    <div className="single-item">
                                        <div className="text-center py-4">
                                            <img src="assets/images/user.png" className="img-fluid m-auto d-block" alt="Student" />
                                            <h5 className="mt-3 fw-bold">John Leo</h5>
                                            <h6>All the teachers here are very helpful and all of them are native which helps with the accent too. The classes are not only informative, but also a lot of fun and exciting.</h6>
                                        </div>
                                        
                                        
                                      
                                    </div>
                                </div>
                                <div className="col-md-6 hidden-md">

                                <div className="about-img mt-3">
                                <div className="img_1" data-aos="zoom-in" data-aos-duration={550}>
                                    <div className="border-line" />
                                    <img src="assets/images/about1.jpg" className="img-fluid" alt="About" />
                                </div>
                               
                            </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default JoinUs